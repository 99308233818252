import logo from './logo.svg';
import './App.css';
import Home from '../src/pages/home'
import About from '../src/pages/about'
import Contact from '../src/pages/contact'
import Portfolio from '../src/pages/portfolio'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App bg-[#FFFDF4] overflow-x-hidden">
        <Routes>
          <Route path='/' element={< Home />}></Route>
          <Route path='/about' element={< About />}></Route>
          <Route path='/contact' element={< Contact />}></Route>
          <Route path='/portfolio' element={< Portfolio />}></Route>
          <Route path='*' element={< Home />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
