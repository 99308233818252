import React from 'react'
import headlogo from '../images/bottomlogo.svg'
import linkin from '../images/ln.svg'
import twitter from '../images/twitter.svg'
import insta from '../images/in.svg'
import fb from '../images/fb.svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
      <div className='bg-[#F8F8F8] p-5 lg:p-10 flex flex-col space-y-8'>
        <div className='flex flex-row justify-between space-x-10 lg:space-x-40'>
          <div className='flex flex-col space-y-8 text-left text-[#555555]'>
            <span className=''><img className='w-[450px] lg:w-[225.53px]' src={headlogo}/></span>
            <Link to='/home'>Home</Link>
            <Link to='/about'>About</Link>
            <Link to='/portfolio'>Portfolio</Link>
            <Link to='/contact'>Contact</Link>
          </div>
          <div className=''><p className='text-[#0C0900] font-[400] text-[24px] lg:text-[40px] text-left'>Your trusted IT partner with a proven track record of success. Start your journey!</p></div>
        </div>
        <div className=''>
          <hr className='text-[#E8E8E8] h-[1px]'/>
        </div>
        <div className='flex flex-col lg:flex-row lg:justify-between space-y-3 lg:space-y-0'>
          <div className='flex flex-row space-x-3'>
            {/* <Link to='https://instagram.com' target='_blank'><img src={insta}/></Link>
            <Link to='https://facebook.com' target='_blank'><img src={fb}/></Link> */}
            <Link to='https://www.linkedin.com/company/goldsalem/about/' target='_blank'><img src={linkin}/></Link>
            {/* <Link to='https://twitter.com' target='_blank'><img src={twitter}/></Link> */}
          </div>
          <div><p className='text-[#4F4F4F] font-[400] text-[16px] text-start lg:text-end'>© Copyright 2024, All Rights Reserved by Goldsalem</p></div>
        </div>
      </div>
    </>
  )
}

export default Footer