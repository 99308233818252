import React from 'react'

function Readyto() {
  return (
    <div className='bg-white space-y-8 px-5 lg:px-10 pb-10 lg:pb-20'>
    <div className="bg-black lg:bg-[url('images/readyto.svg')] flex flex-col lg:flex-row text-white rounded-[8px] p-5 space-y-7 lg:space-y-0 lg:justify-between lg:items-center py-10">
      <div className='flex flex-col text-left space-y-3'>
        <span className='text-[32px] leading-[42px] lg:text-[40px] font-[400] lg:leading-[50px]'>Ready to make your project a reality?</span>
        <span className='text-[20px] lg:text-[24px] font-[300]'>Let's talk about how we can turn your vision into reality.</span>
      </div>
      <div className='text-start w-full lg:w-auto'><button onClick={() => window.location.href='/contact'} className='w-full rounded-[8px] text-[#0C0900] bg-white p-2 px-3'>Contact Us</button></div>
    </div>
    </div>
  )
}

export default Readyto